import { Injectable } from '@angular/core';
import { CoreLocalStorageService } from './local-storage/local-storage.service';
import { CoreCookiesService } from './cookies/core-cookies.service';
import { CoreSessionStorageService } from './session-storage/core-session-storage.service';
import {
  DuplicateEntry,
  KeyStorageHelper,
} from 'src/app/share/helpers/key-storage/key-storage.helper';
import { LocalStorageConfigKeys } from 'src/app/config/app-storage/local-storage.config';
import { SessionStorageConfigKeys } from 'src/app/config/app-storage/session-storage.config';
import { CookieStorageConfigKeys } from 'src/app/config/app-storage/cookies.config';
import { CacheStorageConfigKeys } from 'src/app/config/app-storage/cache-storage.config';
import _ from 'lodash';

export enum StorageLocation {
  LOCAL_STORAGE = 'LOCAL_STORAGE',
  SESSION_STORAGE = 'SESSION_STORAGE',
  COOKIES = 'COOKIES',
}

type Options = {
  location: StorageLocation;
  expireInSeconds?: number;
  excepts?: Array<string>;
};

@Injectable({
  providedIn: 'root',
})
export class AppStorageService {
  public readonly localStorageKeys = LocalStorageConfigKeys;
  public readonly sessionStorageKeys = SessionStorageConfigKeys;
  public readonly cookiesKeys = CookieStorageConfigKeys;
  constructor(
    private readonly localStorage: CoreLocalStorageService,
    private readonly sessionStorage: CoreSessionStorageService,
    private readonly cookie: CoreCookiesService
  ) {}

  public setItem(key: string, value: any, options: Options) {
    switch (options.location) {
      case StorageLocation.LOCAL_STORAGE:
        this.localStorage.setItem(key, value);
        break;
      case StorageLocation.SESSION_STORAGE:
        this.sessionStorage.setItem(key, value);
        break;
      case StorageLocation.COOKIES:
        this.cookie.setCookie(key, value, options.expireInSeconds);
        break;
      default:
        break;
    }
  }

  public getItem<T>(key: string, options: Options): T | any {
    switch (options.location) {
      case StorageLocation.LOCAL_STORAGE:
        return this.localStorage.getItem<T>(key);
      case StorageLocation.SESSION_STORAGE:
        return this.sessionStorage.getItem<T>(key);
      case StorageLocation.COOKIES:
        return this.cookie.getCookie(key);
      default:
        break;
    }
  }

  public removeItems(key: string[], options: Options) {
    switch (options.location) {
      case StorageLocation.LOCAL_STORAGE:
        this.localStorage.removeItems(key);
        break;
      case StorageLocation.SESSION_STORAGE:
        this.sessionStorage.removeItems(key);
        break;
      case StorageLocation.COOKIES:
        this.cookie.deleteCookies(key);
        break;
      default:
        break;
    }
  }

  public removeAllIn(options: Options) {
    switch (options.location) {
      case StorageLocation.LOCAL_STORAGE:
        if (options.excepts) {
          const arrayKeys = Object.values(
            KeyStorageHelper.flattenObject(this.localStorageKeys)
          ).filter((e: any) => !options.excepts?.includes(e)) as Array<string>;
          this.localStorage.removeItems(arrayKeys);
          return;
        }
        // Find all key from storage;
        this.localStorage.clear();
        break;
      case StorageLocation.SESSION_STORAGE:
        if (options.excepts) {
          const arrayKeys = Object.values(
            KeyStorageHelper.flattenObject(this.sessionStorageKeys)
          ).filter((e: any) => !options.excepts?.includes(e)) as Array<string>;
          this.sessionStorage.removeItems(arrayKeys);
          return;
        }
        this.sessionStorage.clear();
        break;
      case StorageLocation.COOKIES:
        throw new Error(`Method not allowed`);
      default:
        break;
    }
  }

  verifyStorageKey = () => {
    const keyStorageHelper = new KeyStorageHelper();
    // Verify local storage keys
    const duplicatedLocalStorageKey = keyStorageHelper.findDuplicateObjects(
      LocalStorageConfigKeys
    );
    if (duplicatedLocalStorageKey.length > 0) {
      this.throwErrorWhenDuplicatedKey(duplicatedLocalStorageKey);
    }

    // Kiểm tra trùng session storage key
    const duplicatedSessionKey = keyStorageHelper.findDuplicateObjects(
      SessionStorageConfigKeys
    );
    if (duplicatedSessionKey.length > 0) {
      this.throwErrorWhenDuplicatedKey(duplicatedSessionKey);
    }

    // Kiểm tra trùng cookie key
    const duplicatedCookieKey = keyStorageHelper.findDuplicateObjects(
      CookieStorageConfigKeys
    );
    if (duplicatedCookieKey.length > 0) {
      this.throwErrorWhenDuplicatedKey(duplicatedCookieKey);
    }

    // Kiểm tra trùng cache storage key.
    const duplicatedCacheStorageKey = keyStorageHelper.findDuplicateObjects(
      CacheStorageConfigKeys
    );
    if (duplicatedCacheStorageKey.length > 0) {
      this.throwErrorWhenDuplicatedKey(duplicatedCacheStorageKey);
    }
  };

  throwErrorWhenDuplicatedKey = (duplicateValue: DuplicateEntry[]) => {
    _.forEach(duplicateValue, (value) => {
      throw new Error(
        `Lỗi trùng giá trị ${value.key} trong các khóa ${value.values}`
      );
    });
  };
}
