// TODO: Lưu mấy dữ liệu mà tự động gửi lên server.
export const CookieStorageConfigKeys = {
  global: {

  },
  features: {
    noi_quy_lao_dong: {
      tglvs: {
        currentSelectedItem: "features.noi_quy_lao_dong.tglvs.currentSelectedItem"
      },
      nghi_pheps: {
        currentSelectedItem: "features.noi_quy_lao_dong.nghi_pheps.currentSelectedItem"
      },
      hoc_tap_cong_tacs: {
        currentSelectedItem: "features.noi_quy_lao_dong.hoc_tap_cong_tacs.currentSelectedItem"
      }
    }
  }
}
